/* eslint-disable @next/next/no-img-element */
import { Spacer, Text } from '@zeit-ui/react'
import { FormattedMessage } from 'react-intl'
import LocaleSwitcher from 'components/locale-switch'
import { ReactNode } from 'react'
interface ErrorPageProps {
  title?: string | ReactNode
  description?: string | ReactNode
}

export default function ErrorPage(props: ErrorPageProps) {
  return (
    <div className="container h-screen max-w-2xl mx-auto leading-normal text-center">
      <main>
        <img src="/error-exclamation.svg" alt="Error Exclamation" className="w-40 max-w-full" />

        <Spacer y={2} />

        <Text h1 className="text-5xl">
          {props.title ? props.title : <FormattedMessage id="page.error.title" />}
        </Text>

        <Spacer y={1} />

        <Text p className="description">
          {props.description ? (
            props.description
          ) : (
            <FormattedMessage
              id="page.error.description"
              values={{
                email: (...mail) => (
                  <a href={`mailto:${mail}`} className="link">
                    {mail}
                  </a>
                ),
              }}
            />
          )}
        </Text>
      </main>

      <div className="fixed bottom-0 left-0 mb-4 ml-4">
        <LocaleSwitcher />
      </div>

      <style jsx>{`
        .container {
          min-height: var(--vh);
          padding: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        main {
          padding: 5rem 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .title {
          margin: 0;
          line-height: 1.15;
          font-size: 3rem;
        }

        .title,
        .description {
          text-align: center;
        }

        .description {
          line-height: 1.5;
        }
      `}</style>
    </div>
  )
}
