import { Skeleton } from 'antd'
import CenterScreenContent from 'components/center-screen-content'
import React from 'react'

export default function AgreementLoading() {
  return (
    <div className="fixed w-full h-full">
      <CenterScreenContent>
        <div className="space-y-4 w-full max-w-screen-sm  text-center">
          <div className="bg-gray-100 h-40 w-80 mx-auto max-w-full ">
            <Skeleton.Button size="large" active className="w-full h-full" />
          </div>
          <div className="w-4/5 mx-auto">
            <Skeleton.Button className="w-full" active />
          </div>
          <div className="w-1/2 mx-auto">
            <Skeleton.Button active className="w-full h-6" />
          </div>
          <div className="text-center pt-8">
            <Skeleton.Button size="large" active className="w-40" />
          </div>
        </div>
      </CenterScreenContent>
    </div>
  )
}
