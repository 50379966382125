import { ReactNode } from 'react'

interface CenterScreenContentProps {
  children: ReactNode
}

export default function CenterScreenContent(props: CenterScreenContentProps) {
  return (
    <div className="flex min-h-screen place-items-center justify-center container flex-col w-full">
      <div className="items-center flex flex-col justify-center min-h-screen place-items-center w-full">{props.children}</div>
    </div>
  )
}
