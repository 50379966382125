import { createGlobalState } from 'react-hooks-global-state'
import enums from 'typings/kenai/enums'

const initialState = {
  error: {
    key: '' as keyof typeof enums.OPERATION_KEYS | keyof typeof enums.VALIDATION_FAILURES | '' | undefined,
    message: '' as string | undefined,
  },
  entityBrand: {
    primaryColor: '#4cb75b',
    companyName: 'Kenai',
  },
}

const { useGlobalState } = createGlobalState(initialState)

export default useGlobalState
