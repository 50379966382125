import CenterScreenContent from 'components/center-screen-content'
import GenericError from 'components/error-page'
import Layout from 'components/layout'
import Loading from 'components/loading'
import AgreementLoading from 'components/loading/agreement-loading'
import Cookies from 'cookies'
import { isLocale, parseMessageLocale } from 'i18n/utils'
import API, { getChannelConfigResponse } from 'lib/api' // getChannelConfigResponse
import { GetServerSideProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import React, { useEffect, useMemo, useState } from 'react'
import useGlobalState from 'store/global-state'
import { ViewTypes } from 'typings/view-types'
import removeLocationHash from 'utils/removeLocationHash'
import tokenHandler from 'utils/tokenHandler'

const AgreementView = dynamic(() => import('components/views/welcome-view'), { loading: AgreementLoading })

const loading = () => <Loading />

const GroupedPreScreening = dynamic(() => import('components/views/grouped-pre-screening'), {
  loading,
})

const IdmMethod = dynamic(() => import('components/views/identification-method'), {
  loading,
})

const PreScreenView = dynamic(() => import('components/views/pre-screen'), {
  loading,
})

const Instructions = dynamic(() => import('components/views/instructions-view'), {
  loading,
})

interface LandingProps {
  data: getChannelConfigResponse
  primaryColor: string
  entityDefaultLocale: string
  locale
  isSSOFlow: boolean
  errorMessage?: string
  uisDualModeEnabled?: boolean
  uisSsoButtonText?: string
  uisIdentifiersButtonText?: string
  isGroupedView?: boolean
}

const LandingPage: NextPage<LandingProps> = (props) => {
  const [currentView, setCurrentView] = useState<ViewTypes>()
  const [instructionStatus, setInstructionStatus] = useState<'failed' | 'passed'>('failed')
  const [primaryColor, updatePrimaryColor] = useState(props.primaryColor || '#fff')
  const [, setEntityBrand] = useGlobalState('entityBrand')

  useEffect(() => {
    removeLocationHash()
    return () => {
      removeLocationHash()
    }
  }, [])

  useEffect(() => {
    setCurrentView(props.uisDualModeEnabled ? 'identification-method' : 'agreement')
  }, [props.uisDualModeEnabled])

  const channelConfig = useMemo(() => {
    if (props.data && props.data.processingResponse && props.data.processingResponse.channelConfig) {
      return props.data.processingResponse.channelConfig
    } else {
      return undefined
    }
  }, [props.data])

  const processingLocations = useMemo(() => {
    if (channelConfig) {
      if (channelConfig.locations.length > 1) {
        return channelConfig.locations.filter((location) => location.EntityHierarchy !== channelConfig.mappedLocationHierarchy)
      } else {
        return channelConfig.locations
      }
    } else {
      return undefined
    }
  }, [channelConfig])

  const defaultLocation = useMemo(() => {
    if (processingLocations && processingLocations.length) {
      return processingLocations[0]
    } else {
      return undefined
    }
  }, [processingLocations])

  const handleAgreementAcceptToggle = (checked) => {
    if (!checked) {
      setCurrentView('agreement')
    }
  }
  const handleAgreementContinue = () => setCurrentView('prescreening')

  const handleEntitySelect = (EntityHierarchy: string) => {
    channelConfig.locations.some((location) => {
      if (location.EntityHierarchy === EntityHierarchy) {
        localStorage.setItem('precheck-EntityHierarchy', EntityHierarchy)
        setEntityBrand({
          primaryColor: location.primaryColor,
          companyName: `${location.companyName} - ${location.locationShortDescription}`,
        })
        updatePrimaryColor(location.primaryColor)
        return true
      }
    })
  }

  const handleInstructions = (result: typeof instructionStatus = 'failed') => {
    setInstructionStatus(result)
    setCurrentView('instructions')
    if (channelConfig.kioskMode) {
      setTimeout(window.location.reload.bind(window.location), 3000)
    }
  }

  const handleSSOIdentifiersMissing = () => setCurrentView('ssoidentifiersmissing')

  const errorMessage = props.errorMessage || undefined

  if (!channelConfig || !processingLocations || !defaultLocation) {
    console.error(props.data)
    if (errorMessage) {
      return <GenericError title="An error has occurred" description={errorMessage} />
    } else {
      return <GenericError />
    }
  }

  if (currentView === 'ssoidentifiersmissing') {
    return (
      <Layout primaryColor={primaryColor}>
        <GenericError
          title="An error has occurred"
          description="There are missing identifiers for your single sign-on profile - please contact your support department to verify your SSO setup."
        />
      </Layout>
    )
  }

  if (currentView === 'identification-method') {
    return (
      <Layout primaryColor={primaryColor}>
        <IdmMethod
          src={props.data.processingResponse.channelConfig.logo}
          uisSsoButtonText={props.uisSsoButtonText}
          uisIdentifiersButtonText={props.uisIdentifiersButtonText}
        />
      </Layout>
    )
  }

  if (channelConfig?.appVariantSettings?.layout === 'multiStepForm') {
    return (
      <Layout primaryColor={primaryColor} title={channelConfig?.appVariantSettings?.textOverrides?.welcomeTitle}>
        <GroupedPreScreening
          onSSOIdentifiersMissing={handleSSOIdentifiersMissing}
          onEntitySelect={handleEntitySelect}
          locations={processingLocations}
          selectionGrouping={channelConfig?.appVariantSettings?.selectionGrouping}
          kioskMode={!!channelConfig.kioskMode}
          userType={channelConfig.userTypeOverride ?? 'employees'}
          tokenModeEnabled={!!channelConfig.tokenModeEnabled}
          isSSOFlow={props.isSSOFlow}
          step={currentView}
          welcomeViewProps={{
            logo: channelConfig.logo,
            entityName: defaultLocation.companyName,
            onAgreementContinue: handleAgreementContinue,
            onAgreementAcceptToggle: handleAgreementAcceptToggle,
            agreement: channelConfig.agreementLink,
            mandatoryAgreementRead: channelConfig.mandatoryAgreementRead,
            kioskMode: !!channelConfig.kioskMode,
            userType: channelConfig.userTypeOverride ?? 'employees',
            headingText: channelConfig?.appVariantSettings?.textOverrides?.welcomeTitle,
            subHeadingText: channelConfig?.appVariantSettings?.textOverrides?.welcomeSubTitle,
            agreementLinkText: channelConfig?.appVariantSettings?.textOverrides?.welcomeAgreementLink,
            hideCompanyLogoOnLanding: channelConfig?.appVariantSettings?.hideCompanyLogoOnLanding,
          }}
          instructionsProps={{
            status: instructionStatus,
            passedMessageOverride: channelConfig.passedMessageOverride ? channelConfig.passedMessageOverride : 'daily',
            instructionCustomMessage: channelConfig.instructionCustomMessage,
            doneScreenHintSet: channelConfig.appVariantSettings.doneScreenHintSet,
          }}
          textOverrides={channelConfig.appVariantSettings.textOverrides}
        />
      </Layout>
    )
  }

  return (
    <Layout primaryColor={primaryColor}>
      {currentView === 'agreement' && (
        <CenterScreenContent>
          <AgreementView
            logo={channelConfig.logo}
            entityName={defaultLocation.companyName}
            onAgreementContinue={handleAgreementContinue}
            onAgreementAcceptToggle={handleAgreementAcceptToggle}
            agreement={channelConfig.agreementLink}
            mandatoryAgreementRead={channelConfig.mandatoryAgreementRead}
            kioskMode={!!channelConfig.kioskMode}
            userType={channelConfig.userTypeOverride ?? 'employees'}
          />
        </CenterScreenContent>
      )}
      {currentView === 'prescreening' && (
        <PreScreenView
          onHandleInstructions={handleInstructions}
          onSSOIdentifiersMissing={handleSSOIdentifiersMissing}
          onEntitySelect={handleEntitySelect}
          locations={processingLocations}
          kioskMode={!!channelConfig.kioskMode}
          userType={channelConfig.userTypeOverride ?? 'employees'}
          tokenModeEnabled={!!channelConfig.tokenModeEnabled}
          isSSOFlow={props.isSSOFlow}
        />
      )}
      {currentView === 'instructions' && (
        <Instructions
          status={instructionStatus}
          passedMessageOverride={channelConfig.passedMessageOverride ? channelConfig.passedMessageOverride : 'daily'}
          instructionCustomMessage={channelConfig.instructionCustomMessage}
        />
      )}
    </Layout>
  )
}

export default LandingPage

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const correlationId = `${Date.now() - Math.floor(Math.random() * 10000)}`
  const locale = 'en'
  const { host } = ctx.req.headers

  const isDev =
    process.env.ENV_DEV ||
    process.env.NODE_ENV === 'development' ||
    host.includes('debug') ||
    host.includes('vercel.app') ||
    host.includes('localhost:3000') ||
    process.env.ENV_PREVIEW

  // console.log({ isDev })

  if (process.env.NODE_ENV !== 'development' && isDev) {
    // eslint-disable-next-line no-console
    console.log({
      'process.env.ENV_DEV': process.env.ENV_DEV,
      "host.includes('debug')": host.includes('debug'),
      "host.includes('vercel.app')": host.includes('vercel.app'),
      'process.env.ENV_PREVIEW': process.env.ENV_PREVIEW,
    })
  }

  const lookupValue = isDev ? 'localhost' : host

  // console.log({ lookupValue })
  const envRetrieved = await API.fetchEnvironment(lookupValue)

  // console.log({ envRetrieved })
  if (envRetrieved) {
    const cookies = new Cookies(ctx.req, ctx.res, {
      httpOnly: true,
      domain: host.split(':')[0],
      secure: true,
      sameSite: 'strict',
      overwrite: true,
    })
    cookies.set('kenai-env', Buffer.from(JSON.stringify(envRetrieved)).toString('base64'))
    const data = await API.getChannelConfig(lookupValue, envRetrieved)
    let primaryColor = ''
    let entityDefaultLocale = ''
    let serverSideOnlyParams = undefined
    if (data.key === 'OPERATION_PROCESSED') {
      primaryColor = data.processingResponse.channelConfig.locations[0].primaryColor
      entityDefaultLocale = parseMessageLocale(data.processingResponse.channelConfig.locations[0].defaultLocale)
      serverSideOnlyParams = data.processingResponse.channelConfig.serverSideOnlyParams

      if (serverSideOnlyParams) {
        delete data.processingResponse.channelConfig.serverSideOnlyParams
      }
      const kcaCookie = cookies.get('kca')
      const ssoToken = tokenHandler.retrieveTokenFromCookie(kcaCookie)
      //check user identification settings
      const { uis, code, token } = ctx.query
      if (serverSideOnlyParams?.SSOSettings?.userIdentificationSettings?.dualModeEnabled && !uis && !code && !token) {
        console.log(correlationId, 'App initialisation')
        return {
          props: {
            data,
            primaryColor,
            entityDefaultLocale,
            locale: isLocale(locale) ? locale : entityDefaultLocale,
            uisDualModeEnabled: true,
            uisSsoButtonText: serverSideOnlyParams?.SSOSettings?.userIdentificationSettings?.ssoButtonText ?? 'SSO',
            uisIdentifiersButtonText: serverSideOnlyParams?.SSOSettings?.userIdentificationSettings?.identifiersButtonText ?? 'IDENTIFIERS',
          },
        }
      } else {
        console.log(
          correlationId,
          'SSO TRACING',
          JSON.stringify({
            token: !token,
            uis: uis,
            ssoRedirectURL: serverSideOnlyParams?.ssoRedirectURL,
            ssoTokenValueLength: ssoToken?.value?.length,
            ssoTokenIsValid: ssoToken?.isValid,
          })
        )
        if (!token && uis !== 'identifiers' && ((serverSideOnlyParams?.ssoRedirectURL && !ssoToken) || (ssoToken && !ssoToken.isValid))) {
          if (ctx.query.code) {
            console.log(correlationId, 'Proceeding to code resolution')
            //we have a code - generate active token and redirect to it
            const activeTokenResult = await API.getAccessTokenForSSOAuthCode(ctx.query.code as string, lookupValue, envRetrieved)
            if (activeTokenResult.key === 'OPERATION_PROCESSED') {
              console.log(correlationId, 'Code resolved')
              const authToken = activeTokenResult.processingResponse.authToken
              const encryptedToken = tokenHandler.getEncryptedToken(authToken)
              console.log(
                correlationId,
                'RESOLVED TOKENS',
                JSON.stringify({
                  authTokenLength: authToken?.length,
                  encryptedTokenLength: encryptedToken?.length,
                })
              )
              cookies.set('kca', encryptedToken)
              return {
                redirect: {
                  destination: serverSideOnlyParams?.SSOSettings?.userIdentificationSettings?.dualModeEnabled ? '/?uis=sso' : '/',
                  permanent: false,
                },
              }
            } else if (activeTokenResult.key === 'GENERIC_ERROR') {
              console.log(correlationId, 'Code generic error')
              return {
                props: {
                  errorMessage: 'We are unable to process your request at this time - please contact your support department.',
                },
              }
            } else {
              console.log(correlationId, 'Code unhandled response')
              if (kcaCookie) {
                cookies.set('kca', '')
              }
              return {
                redirect: {
                  destination: '/',
                  permanent: false,
                },
              }
            }
          } else if (
            ((ctx.query.error && ctx.query.error === 'server_error') || (ctx.query.error && ctx.query.error === 'redirect_uri_mismatch')) &&
            ctx.query.error_description
          ) {
            console.log(
              correlationId,
              'Auth error',
              JSON.stringify({
                ctxQueryError: ctx.query?.error,
                ctxQueryErrorDescription: ctx.query?.error_description,
              })
            )
            if (ctx.query.error_description.includes('Error in SAML response processing')) {
              return {
                props: {
                  errorMessage:
                    'There is an error with your single sign-on - please contact your support department to verify your SSO setup.',
                },
              }
            } else if (ctx.query.error_description.includes('AADB2C')) {
              return {
                props: {
                  errorMessage: `There is an error with your single sign-on - please contact your support department to verify your SSO setup.\nError details - ${ctx.query.error_description}`,
                },
              }
            } else {
              return {
                props: {
                  errorMessage:
                    'There is an error with your single sign-on - please contact your support department to verify your SSO setup. - GENERIC ERROR',
                },
              }
            }
          } else {
            console.log(correlationId, 'Proceeding to SSO redirect')
            if (kcaCookie) {
              cookies.set('kca', '')
            }
            //trigger SSO
            return {
              redirect: {
                destination: serverSideOnlyParams.ssoRedirectURL,
                permanent: false,
              },
            }
          }
        } else {
          console.log(correlationId, 'Proceeding to app flow')
          return {
            ...(ctx.req.url !== '/' &&
              !ctx.req.url.includes('index.json') &&
              !ctx.req.url.includes('?token=') &&
              !ctx.req.url.includes('?uis=') && {
                redirect: {
                  destination: '/',
                  permanent: false,
                },
              }),
            props: {
              data,
              primaryColor,
              entityDefaultLocale,
              locale: isLocale(locale) ? locale : entityDefaultLocale,
              isSSOFlow: !!ssoToken && uis !== 'identifiers' && !token,
            },
          }
        }
      }
    } else {
      console.log(correlationId, 'Proceeding to validation failure')
      return {
        props: {
          ...(data.processingResponse?.validationFailure ? { errorMessage: data.processingResponse.validationFailure } : {}),
        },
      }
    }
  } else {
    console.log(correlationId, 'Proceeding to generic error')
    return {
      props: {
        errorMessage: 'There is an error loading the configuration for this screening URL - please try again or contact support',
      },
    }
  }
}
