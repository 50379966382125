import { useEffect, useState } from 'react'
import { FC } from 'react'
import { CSSTransition } from 'react-transition-group'
import { Animations } from './types'

interface AnimateProps {
  visible?: boolean
  animationIn: Animations
  animationOut: Animations
  initialAnimateOut?: boolean
  mountOnEnter?: boolean
  animateOnMount?: boolean
}

const Animate: FC<AnimateProps> = ({ children, animationIn, animationOut, visible, mountOnEnter = true, animateOnMount = true }) => {
  const [isVisible, setVisible] = useState(visible)

  useEffect(() => {
    if (animateOnMount) setVisible(animateOnMount)
  }, [animateOnMount])

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  return (
    <CSSTransition
      timeout={300}
      in={isVisible}
      class={`animate__animated`}
      classNames={`animate__${visible ? animationIn : animationOut} animate`}
      mountOnEnter={mountOnEnter}
    >
      <div>{children}</div>
    </CSSTransition>
  )
}

export default Animate
