import { Button, Popover } from '@zeit-ui/react'
import locales, { languageNames } from 'i18n/locales'
import { MessageLocales, parseMessageLocale } from 'i18n/utils'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { LocaleContext } from '../i18n/locale-provider'
import Animate from './animate'

interface LocaleSwitcherProps {
  fixed?: boolean
}

export const LocaleSwitcher: React.FC<LocaleSwitcherProps> = ({ fixed }) => {
  const { enabled, locale, setLocale } = React.useContext(LocaleContext)
  const intl = useIntl()
  const [visible, setVisible] = useState(false)

  const handleLocaleChange = (locale: MessageLocales) => {
    setLocale(locale)
    changeHandler(false)
  }

  const changeHandler = (next: boolean) => {
    setVisible(next)
  }

  const content = Object.keys(locales).map((messageLocale) => {
    const locale = parseMessageLocale(messageLocale)
    return (
      <Popover.Item key={locale} className="cursor-pointer hover:text-primary" onClick={() => handleLocaleChange(locale)}>
        {languageNames[locale]}
      </Popover.Item>
    )
  })

  return (
    <div className={fixed ? 'fixed bottom-0 left-0 mb-4 ml-4' : ''}>
      {enabled ? (
        <Popover
          content={
            <Animate animationIn="fadeIn" animationOut="fadeOut" visible={visible}>
              {content}
            </Animate>
          }
          area-label={intl.formatMessage({ id: 'switch-language' })}
          placement="topStart"
          visible={visible}
          onVisibleChange={changeHandler}
        >
          <Button size="mini">{languageNames[locale]}</Button>
        </Popover>
      ) : null}
    </div>
  )
}

const LocaleSwitcherPlaceholder = (_props: any) => null

export default LocaleSwitcherPlaceholder
