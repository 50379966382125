import Head from 'next/head'
import React, { ReactNode } from 'react'
import siteName from 'utils/siteName'

interface LayoutProps {
  title?: string
  description?: string
  primaryColor: string
  children: ReactNode
}

export default function Layout(props: LayoutProps) {
  return (
    <>
      <Head>
        <title>{siteName(props.title || 'Pre-Screening')}</title>
        <meta data-rh="true" name="description" content={props.description || 'Your employee pre-screening portal'} />
        <meta name="theme-color" content={props.primaryColor} />
      </Head>
      {props.children}
      <style jsx global>{`
        :root {
          --primary: ${props.primaryColor};
          --shadow: ${props.primaryColor.replace(/[\d.]+\)$/g, '0.2)')};
        }
        .link {
          color: ${props.primaryColor};
        }
        .link:hover {
          border-bottom: 1px solid ${props.primaryColor};
        }
        ::selection {
          background-color: ${props.primaryColor};
        }
        #nprogress .bar {
          background: ${props.primaryColor};
        }
        #nprogress .peg {
          box-shadow: 0 0 10px ${props.primaryColor}, 0 0 5px ${props.primaryColor};
        }
        #nprogress .spinner-icon {
          border-top-color: ${props.primaryColor};
          border-left-color: ${props.primaryColor};
        }
        .ant-checkbox-wrapper:hover .ant-checkbox-inner,
        .ant-checkbox:hover .ant-checkbox-inner,
        .ant-checkbox-input:focus + .ant-checkbox-inner,
        .ant-checkbox-inner,
        .ant-checkbox-checked:after,
        .ant-checkbox-indeterminate .ant-checkbox-inner,
        .ant-checkbox-checked .ant-checkbox-inner {
          border-color: ${props.primaryColor};
        }
        .ant-checkbox-checked .ant-checkbox-inner,
        .ant-checkbox-indeterminate .ant-checkbox-inner::after {
          background-color: ${props.primaryColor};
        }
        .standard-input {
          color: ${props.primaryColor};
        }
        .text-primary {
          color: ${props.primaryColor};
        }
      `}</style>
    </>
  )
}
