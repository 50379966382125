import { Skeleton } from 'antd'
import React from 'react'
import CenterScreenContent from './center-screen-content'

export default function Loading() {
  return (
    <CenterScreenContent>
      <div className="max-w-screen-sm w-full h-full">
        <Skeleton loading active />
      </div>
    </CenterScreenContent>
  )
}
